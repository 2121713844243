import React from "react"
import styled from "styled-components"
import seminars from "../../static/utils/onlineSemiarPlan-2021.json"
import { successColor, darkColor } from "../../static/styles/colors"

const PresentationContainer = () => {
  return (
    <DropShadow className="container">
      <div className="row justify-content-center">
        <div className="col-xl-8 col-12">
          <NoListStyle
            className="nav nav-tabs p-0 ms-auto d-flex flex-row"
            id="myTab"
            role="tablist"
            style={{ borderBottom: "none" }}
          >
            <li className="nav-item p-0 flex-fill bg-dark">
              <a
                className="nav-link p-3 border-0 rounded-0 active text-uppercase h4 text-light text-center"
                id="onlinePresentation-tab"
                data-bs-toggle="tab"
                href="#onlinePresentation"
                role="tab"
                aria-controls="onlinePresentation"
                aria-selected="true"
              >
                Vorträge
              </a>
            </li>
          </NoListStyle>
        </div>
        <div className="col-xl-8 col-12">
          <div className="tab-content bg-primary" id="myTabContent">
            <div
              className="tab-pane fade show active text-light"
              id="onlinePresentation"
              role="tabpanel"
              aria-labelledby="onlinePresentation-tab"
            >
              <div className="container">
                <Scrollbar className="ps-0 pe-3">
                  {seminars.map(seminar => (
                    <li
                      key={seminar.date + seminar.name}
                      className="bg-secondary p-2 m-2"
                    >
                      <div className="container">
                        <span className="text-success text-uppercase">
                          {seminar.name}
                        </span>
                      </div>
                    </li>
                  ))}
                </Scrollbar>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DropShadow>
  )
}
const NoListStyle = styled.ul`
  & > li::marker {
    content: "";
  }
  & > li {
    list-style-image: none;
  }
`

const Scrollbar = styled.ul`
  & > li::marker {
    content: "";
  }
  & > li {
    list-style-image: none;
  }
  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    background: ${darkColor};
  }
  ::-webkit-scrollbar-thumb {
    background: ${successColor};
    cursor: pointer;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: ${successColor};
    cursor: pointer;
  }
  overflow-y: scroll;
  height: 30vw;
  @media screen and (max-width: 992px) {
    height: 75vw;
  }
  @media screen and (max-width: 576px) {
    height: 100vw;
  }
`

const DropShadow = styled.div`
  filter: drop-shadow(0 0 0.25rem black);
`

export default PresentationContainer
