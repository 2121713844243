import React from "react"
import styles from "styled-components"
import { StaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const ImageValue = ({ onImageClick }) => (
  <StaticQuery
    query={graphql`
      query {
        allFile(filter: { relativeDirectory: { eq: "seminare/gallery" } }) {
          edges {
            node {
              name
              id
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  quality: 95
                  formats: [AUTO, WEBP, AVIF]
                  placeholder: NONE
                )
              }
            }
          }
        }
      }
    `}
    render={data => {
      const renderImagesPreview = data.allFile.edges.map(image => (
        <div className="col-md-6 p-3" key={image.node.id}>
          <Hover
            className="p-0 bg-secondary"
            onClick={() => onImageClick(image)}
          >
            <GatsbyImage
              image={getImage(image.node.childImageSharp.gatsbyImageData)}
              alt={image.node.name}
            />
          </Hover>
        </div>
      ))
      return renderImagesPreview
    }}
  />
)

export const Hover = styles.div`
:hover{
    cursor: pointer;
    filter: brightness(50%);
}
@media screen and (max-width: 768px){
  :hover{
      cursor: pointer;
      filter: brightness(100%);
  }
}`

export default ImageValue
