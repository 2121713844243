import React from "react"

export const onlinePresentationCaptionText = (
  <React.Fragment>
    In nur 90 Minuten erleben Sie, wie Sie mit nur drei Zahlen Ihren Betrieb in
    die Gewinnzone bringen. Viele Großhändler, Innungen sowie
    Kreishandwerkerschaften aus ganz Deutschland bieten dieses Seminar für Ihre
    Mitglieder bereits an. Fragen Sie uns gerne nach einem freien Platz in einem
    unserer kommenden Vorträge.
  </React.Fragment>
)
export const bierTastingText = (
  <React.Fragment>
    Eine besondere Form des Vortrages zur Bierdeckelkalkulation ist unser Bier
    Tasting Event. <br />
    Der Vortrag wird aufgelockert durch ein Bier Tasting mit einen Bier
    Sommelier. Diese Art des Vortrages bietet sich insbesondere für
    Einkaufsverbünde an, die Ihren Handwerkerkunden etwas besonderes anbieten
    möchten.
    <br /> Das Event lässt sich sowohl online als auch als Präsenz Event buchen.
    <br />
    Sprechen Sie uns an.
  </React.Fragment>
)
export const bookingText = (
  <React.Fragment>
    Seit über 10 Jahren erfolgreich als Referent unterwegs, mit jährlich über
    150 Seminaren, sichern auch Sie sich Ihren Seminartermin & profitieren auch
    Sie wie viele Andere.
  </React.Fragment>
)
