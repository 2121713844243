import * as React from "react"
import styled from "styled-components"
import bgImage from "../../images/homepage/hero_banner_bg.png"
import { successColor } from "../../static/styles/colors"
import Image from "../common/heroImage"
import { FaArrowRight } from "@react-icons/all-files/Fa/FaArrowRight"
import { ButtonPrimary, Underline } from "../common/styledComponentsHelper"
import { Link } from "gatsby"

const HeroSection = () => {
  return (
    <div className="container-fluid overflow-hidden">
      <BgWallpaper className="row flex-lg-row-reverse align-items-center">
        <div className="col-lg-7">
          <div className="container pt-4 pb-4">
            <Underline className="display-4 fw-bold d-none d-sm-block lh-1 mb-3 text-uppercase text-light mb-3">
              Online und Präsenz Vorträge
            </Underline>
            <Underline className="display-4 fw-bold lh-1 d-sm-none mb-3 text-uppercase text-light mb-3">
              Online und Präsenz Vorträge
            </Underline>
            <p className="display-4 text-uppercase text-light pt-4 mb-4">
              Bierdeckel<span className="d-sm-none d-inline"> </span>kalkulation
              <br />
              Mit 3 Zahlen zu mehr Gewinn!
            </p>
            <Link to="/online-seminar/organic">
              <ButtonPrimary>
                Jetzt kostenlos Anmelden
                <FaArrowRight className="ms-3" color={successColor} />
              </ButtonPrimary>
            </Link>
          </div>
        </div>
        <div className="col-lg-5 p-0">
          <Image
            alt="Hero Banner"
            filenameDesktop="seminare_banner_desktop.png"
            filenameMobile="seminare_banner_mobile.png"
            style={{
              filter: `drop-shadow(10px 0px 25px ${successColor})`,
            }}
          />
        </div>
      </BgWallpaper>
    </div>
  )
}

const BgWallpaper = styled.div`
  background-image: url(${bgImage});
  background-repeat: no-repeat;
  background-size: cover;
`

export default HeroSection
