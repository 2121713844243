import React, { useState } from "react"
import { Formik, Form, Field, ErrorMessage } from "formik"
import * as Yup from "yup"
import TextError from "./common/textError"
import axios from "axios"
import downloadFlyer from "../static/download/flyer.pdf"
import downloadPreisliste from "../static/download/preiseliste.pdf"
import { successColor } from "../static/styles/colors"
import { Link } from "gatsby"

const validationSchema = Yup.object({
  Email: Yup.string()
    .email("E-Mail-Adresse ist nicht valide")
    .required("E-Mail-Adresse muss ausgefüllt sein!"),
  Datenschutz: Yup.boolean().oneOf(
    [true],
    "Bitte die Datenschutzbestimmungen akzeptieren!"
  ),
})

const SimpleMailForm = ({ request }) => {
  const [serverState, setServerState] = useState()
  const initialValues = {
    Email: "",
    Datenschutz: false,
    HoneyPot: "",
    dataSendFrom: "simpleMailForm",
    downloadType: request,
  }

  const chooseDownloadItem = item => {
    switch (item) {
      case "Flyer":
        return downloadFlyer
      case "Preisliste":
        return downloadPreisliste
      default:
        break
    }
  }

  const handleServerResponse = (ok, msg) => {
    setServerState({ ok, msg })
  }

  const onSubmit = (values, submitProps) => {
    axios
      .post("https://fachhandwerk360.de/webmailer/kontakt.php", values)
      .then(response => {
        submitProps.setSubmitting(false)
        submitProps.resetForm()
        handleServerResponse(true)
      })
      .catch(error => {
        submitProps.setSubmitting(false)
        handleServerResponse(false, error.response.data.error)
      })
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {formik => {
        return (
          <div className="pt-0">
            <Form>
              <div className="form-group mt-3">
                <Field
                  type="text"
                  id="HoneyPot"
                  name="HoneyPot"
                  className="form-control border-success border-3 d-none"
                />
                <label htmlFor="Email" className="text-light fs-5 ">
                  E-Mail-Adresse*
                </label>
                <Field
                  type="email"
                  id="Email"
                  name="Email"
                  placeholder="Ihre E-Mail-Adresse"
                  className="form-control border-success border-3"
                />
                <ErrorMessage name="Email" component={TextError} />
              </div>
              <div className="form-group mt-3">
                <label
                  htmlFor="Datenschutz"
                  className="text-gray-500 font-bold"
                >
                  <Field
                    name="Datenschutz"
                    id="Datenschutz"
                    className="me-2 leading-tight"
                    type="checkbox"
                  />
                  <span className="text-light fs-5 ">
                    Ich habe die{" "}
                    <Link
                      to="/datenschutzerklaerung"
                      style={{ color: successColor }}
                    >
                      Datenschutzrichtlinien
                    </Link>{" "}
                    gelesen und akzeptiere diese.
                  </span>
                </label>
                <br />
                <ErrorMessage name="Datenschutz" component={TextError} />
              </div>
              <p className="mt-3 fs-5 text-light">
                Durch die Angabe meiner E-Mail-Adresse bin ich damit
                einverstande, dass Fachhandwerk360 Dreier & Herber GbR Kontakt
                mit mir aufnehmen darf.
              </p>
              <button
                className="shadow-inset-success-sm pt-2 pb-2 rounded-0 mt-3 d-block btn btn-secondary text-uppercase text-success border-success"
                type="submit"
                disabled={!formik.isValid || formik.isSubmitting}
              >
                Zum Download
              </button>
              {serverState && (
                <div className="fs-5 text-light mt-3">
                  <a
                    href={chooseDownloadItem(request)}
                    download
                    className="text-decoration-none"
                    style={{ color: successColor }}
                  >
                    Herunterladen
                  </a>
                </div>
              )}
            </Form>
          </div>
        )
      }}
    </Formik>
  )
}

export default SimpleMailForm
