import React, { useState } from "react"
import ImageValue from "./imageValue"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { StaticImage } from "gatsby-plugin-image"

const ImageContainer = () => {
  const [currentImage, setCurrentImage] = useState(
    <StaticImage
      src="../../images/seminare/gallery/bier_mit_allem_auf_kiste.png"
      alt="bier_mit_allem_auf_kiste"
      layout="fullWidth"
      quality={95}
      formats={["AUTO", "WEBP", "AVIF"]}
      placeholder="none"
      className="w-100 h-100 d-block"
    />
  )
  const handleImageClick = imageObj => {
    setCurrentImage(
      <GatsbyImage
        image={getImage(imageObj.node.childImageSharp.gatsbyImageData)}
        alt={imageObj.node.name}
        className="w-100 h-100 d-block"
      />
    )
  }
  return (
    <div className="container pt-5 pb-5">
      <div className="row ">
        <div className="col-xl-6 p-3 d-none d-md-block">
          <div className="h-100 d-block bg-secondary">{currentImage}</div>
        </div>
        <div className="col-xl-6">
          <div className="container p-0">
            <div className="row">
              <ImageValue onImageClick={handleImageClick} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ImageContainer
