import React from "react"
import Slider from "react-slick"
import { StaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { MdKeyboardArrowRight } from "@react-icons/all-files/md/MdKeyboardArrowRight"
import { MdKeyboardArrowLeft } from "@react-icons/all-files/md/MdKeyboardArrowLeft"
import { successColor } from "../../static/styles/colors"

const SommelierSlider = () => (
  <StaticQuery
    query={graphql`
      query {
        allFile(filter: { relativeDirectory: { eq: "seminare/sommelier" } }) {
          edges {
            node {
              name
              id
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  quality: 95
                  formats: [AUTO, WEBP, AVIF]
                  placeholder: NONE
                )
              }
            }
          }
        }
      }
    `}
    render={data => {
      const settings = {
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        infinite: true,
        swipeToSlide: true,
        focusOnSelect: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 992,
            settings: {
              arrows: false,
            },
          },
        ],
      }
      const renderSimpleImages = data.allFile.edges.map(image => (
        <div className="container d-block mx-auto" key={image.node.id}>
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <GatsbyImage
                image={getImage(image.node.childImageSharp.gatsbyImageData)}
                alt={image.node.name}
              />
            </div>
          </div>
        </div>
      ))
      return <Slider {...settings}>{renderSimpleImages}</Slider>
    }}
  />
)
const SampleNextArrow = props => {
  return (
    <div
      className="position-absolute"
      onKeyDown={props.onClick}
      onClick={props.onClick}
      role="button"
      tabIndex={0}
      style={{
        cursor: "pointer",
        right: "15%",
        top: "50%",
        WebkitTransform: "translate(0, -50%)",
        msTransform: "translate(0, -50%)",
        transform: "translate(0, -50%)",
      }}
    >
      <MdKeyboardArrowRight
        size="150px"
        className="me-3"
        color={successColor}
      />
    </div>
  )
}

const SamplePrevArrow = props => {
  return (
    <div
      className="position-absolute"
      onKeyDown={props.onClick}
      onClick={props.onClick}
      role="button"
      tabIndex={0}
      style={{
        zIndex: "10",
        cursor: "pointer",
        left: "15%",
        top: "50%",
        WebkitTransform: "translate(0, -50%)",
        msTransform: "translate(0, -50%)",
        transform: "translate(0, -50%)",
      }}
    >
      <MdKeyboardArrowLeft size="150px" className="me-3" color={successColor} />
    </div>
  )
}

export default SommelierSlider
